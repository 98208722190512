import Vue from "vue";
import VueRouter from "vue-router";
import http from "@/http.js";
import store from "@/store/index.js"

import Home from "@/views/Home.vue";
import TanulmanyiTajekoztato from '@/views/TanulmanyiTajekoztato.vue'
// import AdminLogin from '@/views/AdminLogin.vue'
const AdminLogin = () => import(/* webpackChunkName: "admin-login" */ '@/views/AdminLogin.vue');
const AdminTajekoztato = () => import(/* webpackChunkName: "admin-tajekoztato" */ '@/views/AdminTajekoztato.vue')
const AdminSzemeszterLathatosag = () => import(/* webpackChunkName: "admin-szem-lathatosag" */ '@/views/AdminSzemeszterLathatosag.vue')
const AdminMintatervLathatosag = () => import(/* webpackChunkName: "admin-min-lathatosag" */ '@/views/AdminMintatervLathatosag.vue')

const TantargyLista = () => import(/* webpackChunkName: "tantargy-lista" */ '@/views/TantargyLista.vue')
const Matrix = () => import(/* webpackChunkName: "matrix" */ '@/views/Matrix.vue')
const UserMatrix = () => import(/* webpackChunkName: "user-matrix" */ '@/views/UserMatrix.vue')
const LoginTeszt = () => import(/* webpackChunkName: "login-teszt" */ '@/views/LoginTeszt.vue')


import Dashboard from "@/components/Dashboard.vue"

Vue.use(VueRouter);

async function requireAuth(to, from, next) {
  try {
    const response = await http.get("/validate", store.state.user)
    if(response.status == 'OK' && response.user != false) {
      store.commit('setUser', response.user)
      next();
    } else {
      router.push('/adminLogin').catch(()=>{});
    }
  } catch (e) {
    // return
    console.error(e)
  }
}

const routes = [
  //l5l3bVZgWL3AFhiiu8wfEBvkhLN4N5
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/tematika/:kod?",
    name: "Home",
    component: Home,
  },
  {
    path: '/loginteszt/:id',
    name: "LoginTeszt",
    component: LoginTeszt
  },
  {
    path: '/tanulmanyi_tajekoztato/:lang/:id',
    name: 'TanulmanyiTajekoztato',
    component: TanulmanyiTajekoztato,
  },
  {
    path: '/adminlogin',
    name: 'AdminLogin',
    component: AdminLogin,
  },
  {
    path: '/admin',
    component: Dashboard,
    beforeEnter: requireAuth,
      children: [
        {
          path: '/admintajekoztato',
          name: 'AdminTajekoztato',
          component: AdminTajekoztato,
          beforeEnter: requireAuth,
        },
        {
          path: '/adminazemeszterlathatosag',
          name: 'AdminSzemeszterLathatosag',
          component: AdminSzemeszterLathatosag,
          beforeEnter: requireAuth,
        },
        {
          path: '/adminmintatervlathatosag',
          name: 'AdminMintatervLathatosag',
          component: AdminMintatervLathatosag,
          beforeEnter: requireAuth,
        },
      ]
  },
  {
    path: '/tantargy_lista/:lang/:id',
    name: 'TantargyLista',
    component: TantargyLista,
    meta: {
      title: String
    }
  },
  {
    path: '/matrix/:lang/:id',
    name: "Matrix",
    component: Matrix,
  },
  {
    path: '/tanulo_matrix/:lang/:modul_kod/:minta_kod',
    name: 'UserMAtrix',
    component: UserMatrix,
    // beforeEnter: checkLogin
  },
  {path: '*', component: Home },
];

const router = new VueRouter({
  base: process.env.BASE_URL || '',
  mode: process.env.NODE_ENV === 'production' ? 'hash' : 'hash',
  routes,
});

export default router;
