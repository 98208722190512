<template>
  <v-app>
    <v-main class="px-0 mt-2">
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "App",
  data: () => ({}),
  mounted() {},
};
</script>
<style lang="css">

* {
  box-sizing: border-box !important;
}
body {
  background: linear-gradient(135deg, #00adcb 20%, #0c3c60);
  font-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
}
.theme--light.v-application {
  background: transparent !important;
}
body::after {
  background-color: rgba(255, 255,255, .95);
  content: "";
  height: 100%;
  position: fixed;
  inset: 0;
  clip-path: ellipse(48% 100% at 33% 69%);
  z-index: -1;
}
.v-icon::after {
  color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00adcb;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0c3c60;
}
</style>

