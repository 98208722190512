import './plugins/bootstrap-vue'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from 'vue-cookies';
import http from "@/http";
Vue.use(VueCookies);

Vue.config.productionTip = false;

Vue.prototype.$config = {
  header: {
    height: 60
  },
  sidebar: {
    width: 260
  },
  footer: {
    height: 60
  }
};
window.vue =new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
