<template>
  <v-app>
    <v-app-bar :fixed="true" app flat dark color="#0C3C60">
      <v-app-bar-nav-icon @click="$store.state.navigation_drawer = !$store.state.navigation_drawer">
        <v-icon large>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-main>
      <v-navigation-drawer app v-model="$store.state.navigation_drawer" width="320" color="#0C3C60" class="px-0">
        <v-card elevation="0" flat rounded="0" color="#0C3C60">
          <v-card-title>
            <h4 class="mx-auto text-white">Tanulmányi tájékoztató</h4>
          </v-card-title>
          <v-card-text class="text-center"><h4 class="mx-auto caption text-white">Adminisztrációs felület</h4></v-card-text>
        </v-card>
       <v-divider :thickness="8" class="my-1 border-opacity-100"></v-divider>
        <v-list :lines="false" density="compact" class="" dark rounded >
          <v-list-item to="/admintajekoztato" class="ma-2 rounded-lg hover pa-0">
            <v-list-item-icon class="mx-3"><v-icon>mdi-book</v-icon></v-list-item-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }"> 
                <v-list-item-content v-bind="attrs" v-on="on" class="pb-0"><v-list-item-title><p class="pb-0 mb-2">Tanulmányi tájékoztatók</p></v-list-item-title></v-list-item-content>
              </template>
              <p class="m-0">Tanulmányi tájékoztatók</p>
            </v-tooltip> 
          </v-list-item>
          <v-list-item to="/adminazemeszterlathatosag" class="ma-2 rounded-lg hover pa-0">
            <v-list-item-icon class="mx-3"><v-icon>mdi-clipboard-check</v-icon></v-list-item-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }"> 
                <v-list-item-content v-bind="attrs" v-on="on" class="pb-0"><v-list-item-title><p class="pb-0 mb-2">Szemeszter Tematika megtekintés</p></v-list-item-title></v-list-item-content>    
              </template>
              <p class="m-0">Szemeszter Tematika megtekintés</p>
            </v-tooltip> 
          </v-list-item>
          <v-list-item to="/adminmintatervlathatosag" class="ma-2 rounded-lg hover pa-0">
            <v-list-item-icon class="mx-3"><v-icon>mdi-eye</v-icon></v-list-item-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }"> 
                <v-list-item-content v-bind="attrs" v-on="on" class="pb-0"><v-list-item-title><p class="pb-0 mb-2">Mintatantervek láthatósága</p></v-list-item-title></v-list-item-content>    
              </template>
              <p class="m-0">Mintatantervek láthatósága</p>
            </v-tooltip> 
          </v-list-item>
        </v-list>
       <template v-slot:append>
          <v-list class="pa-2" :lines="false" density="compact" dark rounded>
            <v-list-item @click="logout" class="ma-2 rounded-lg">
              <v-list-item-icon class="mr-4"><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content class="pb-0"><v-list-item-title> <p class="pb-0 mb-2">Kijelentkezés</p> </v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
      <v-container class="py-0 px-6 mb-6" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    lenghtRules: [
      (v) => v.length != 3 || "Pontosan csak 3 hónapot lehet kiválasztani",
    ],
    error: [],
  }),

  mounted() {
  },

  methods: {
    logout() {
      this.$store.state.user = {};
      this.$cookies.remove("jelenleti_token");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
</style>