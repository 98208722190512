<template>
  <div>
    <div v-if="subject.length > 1">
      <v-card elevation="0">
        <p class="pa-4 display-1 sticky">
          {{ search.department.F0 }} - {{ search.department.F1 }}
        </p>
        <v-simple-table class="border">
          <template>
            <thead>
              <tr>
                <th>
                  {{ lang.table_sorszam }}
                </th>
                <th>
                  {{ lang.targy }}
                </th>
                <th>{{ lang.targykod }}</th>
                <th>
                  {{ lang.tanszek }}
                </th>
                <th>
                  {{ lang.tanszekkod }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sub, index) in subject" :key="index">
                <td class="">{{ index + 1 }}</td>
                <td class="">
                  <a
                    href="javascript:void(0)"
                    @click="tantargytematika(sub.F2)"
                    >{{ sub.F3 }}</a
                  >
                </td>
                <td class="">{{ sub.F2 }}</td>
                <td class="">{{ sub.F1 }}</td>
                <td class="">{{ sub.F0 }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
    <div v-if="subject.length == 1">
      <v-card elevation="0">
        <v-card-text class="m-0 p-0">
          <div class="p-4">
            <p class="pa-4 display-1 sticky">
              {{ subject[0].F3 }} - {{ subject[0].F2 }}
            </p>
            <v-simple-table class="border">
              <template>
                <tbody>
                  <tr>
                    <td>Tárgy / Subject</td>
                    <td>{{ subject[0].F3 }}</td>
                  </tr>
                  <tr>
                    <td>Tárgykód / Subject Code</td>
                    <td>{{ subject[0].F2 }}</td>
                  </tr>
                  <tr>
                    <td>Tanszék / Department</td>
                    <td>{{ subject[0].F1 }}</td>
                  </tr>
                  <tr>
                    <td>Tanszék kód / Department code</td>
                    <td>{{ subject[0].F0 }}</td>
                  </tr>
                  <tr v-for="(item, key) in subject[0].pdfs" :key="key">
                    <td>
                      Tantárgytematika / Syllabus <b>{{ item.Félév }}</b>
                    </td>
                    <td>
                      <a @click="download(item, subject[0].F2)">Letöltés / Download</a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialog" width="50%">
        <v-card>
          <div class="d-flex justify-end mx-2 py-2">
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </div>
          <v-card-text class="m-0 p-0">
            <div class="p-4">
              <p class="pa-4 display-1 sticky">
                {{ subsubject.F3 }} - {{ subsubject.F2 }}
              </p>
              <v-simple-table class="border">
                <template>
                  <tbody>
                    <tr>
                      <td>Tárgy / Subject</td>
                      <td>{{ subsubject.F3 }}</td>
                    </tr>
                    <tr>
                      <td>Tárgykód / Subject Code</td>
                      <td>{{ subsubject.F2 }}</td>
                    </tr>
                    <tr>
                      <td>Tanszék / Department</td>
                      <td>{{ subsubject.F1 }}</td>
                    </tr>
                    <tr>
                      <td>Tanszék kód / Department code</td>
                      <td>{{ subsubject.F0 }}</td>
                    </tr>
                    <tr v-for="(item, key) in subsubject.pdfs" :key="key">
                      <td>
                        Tantárgytematika / Syllabus <b> {{ item.Félév }} </b>
                      </td>
                      <td>
                        <p>
                          <a @click="download(item, subsubject.F2)">Letöltés / Download</a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    subject: Array,
    lang: Object,
    search: Object,
  },
  data: () => ({
    pdfs: [],
    subpdfs: [],
    dialog: false,
    subsubject: {},
  }),
  mounted() {},
  methods: {
    async tantargytematika(index) {
      try {
        this.subsubject = this.subject.filter((item) => item.F2 == index)[0];
        const response = await this.$http.post("/getSubPdfs", this.subsubject);
        this.subsubject.pdfs = response.pdfs;
        this.dialog = true;
      } catch (e) {
        console.error(e);
      }
    },

    async download(item, code) {
      console.log(item);
      console.log(code);
      try {
        var post = {
          code: code,
          felev: item.Félév,
        };
        const response = await this.$http.post("/downloadPdf", post);
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        // link.href = 'https://tata.uninet.hu/api/tmp/' + item.link.pdf_hu + ".pdf";
        link.href = response.file;
          
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
tr:nth-child(odd) {
  background-color: lighten(lightgrey, 14%);
}
a {
  display: inline-block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}
a::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 0.1em;
  transform: scaleX(0);
  background-color: #0C3C60;
  transform-origin: bottom right;
  // transition: transform 0.25s ease-out;
}
a:hover:after {
  transform: scale(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-in;
}
.sticky {
  position: sticky;
  z-index: 5;
  top: 0;
  background-color: #fff;
}
</style>