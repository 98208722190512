var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"fixed":true,"app":"","flat":"","dark":"","color":"#0C3C60"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.$store.state.navigation_drawer = !_vm.$store.state.navigation_drawer}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-menu")])],1)],1),_c('v-main',[_c('v-navigation-drawer',{staticClass:"px-0",attrs:{"app":"","width":"320","color":"#0C3C60"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{staticClass:"pa-2",attrs:{"lines":false,"density":"compact","dark":"","rounded":""}},[_c('v-list-item',{staticClass:"ma-2 rounded-lg",on:{"click":_vm.logout}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',{staticClass:"pb-0"},[_c('v-list-item-title',[_c('p',{staticClass:"pb-0 mb-2"},[_vm._v("Kijelentkezés")])])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.$store.state.navigation_drawer),callback:function ($$v) {_vm.$set(_vm.$store.state, "navigation_drawer", $$v)},expression:"$store.state.navigation_drawer"}},[_c('v-card',{attrs:{"elevation":"0","flat":"","rounded":"0","color":"#0C3C60"}},[_c('v-card-title',[_c('h4',{staticClass:"mx-auto text-white"},[_vm._v("Tanulmányi tájékoztató")])]),_c('v-card-text',{staticClass:"text-center"},[_c('h4',{staticClass:"mx-auto caption text-white"},[_vm._v("Adminisztrációs felület")])])],1),_c('v-divider',{staticClass:"my-1 border-opacity-100",attrs:{"thickness":8}}),_c('v-list',{attrs:{"lines":false,"density":"compact","dark":"","rounded":""}},[_c('v-list-item',{staticClass:"ma-2 rounded-lg hover pa-0",attrs:{"to":"/admintajekoztato"}},[_c('v-list-item-icon',{staticClass:"mx-3"},[_c('v-icon',[_vm._v("mdi-book")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({staticClass:"pb-0"},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',[_c('p',{staticClass:"pb-0 mb-2"},[_vm._v("Tanulmányi tájékoztatók")])])],1)]}}])},[_c('p',{staticClass:"m-0"},[_vm._v("Tanulmányi tájékoztatók")])])],1),_c('v-list-item',{staticClass:"ma-2 rounded-lg hover pa-0",attrs:{"to":"/adminazemeszterlathatosag"}},[_c('v-list-item-icon',{staticClass:"mx-3"},[_c('v-icon',[_vm._v("mdi-clipboard-check")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({staticClass:"pb-0"},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',[_c('p',{staticClass:"pb-0 mb-2"},[_vm._v("Szemeszter Tematika megtekintés")])])],1)]}}])},[_c('p',{staticClass:"m-0"},[_vm._v("Szemeszter Tematika megtekintés")])])],1),_c('v-list-item',{staticClass:"ma-2 rounded-lg hover pa-0",attrs:{"to":"/adminmintatervlathatosag"}},[_c('v-list-item-icon',{staticClass:"mx-3"},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({staticClass:"pb-0"},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',[_c('p',{staticClass:"pb-0 mb-2"},[_vm._v("Mintatantervek láthatósága")])])],1)]}}])},[_c('p',{staticClass:"m-0"},[_vm._v("Mintatantervek láthatósága")])])],1)],1)],1),_c('v-container',{staticClass:"py-0 px-6 mb-6",attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }