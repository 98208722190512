<template>
  <div>
    <Header />
    <v-card elevation="2" class="mx-auto header-row" width="80%">
      <v-row class="ma-0 pa-0">
        <v-col :cols="12" :lg="6" :md="6" :sm="12" class="d-flex align-center"
          ><p class="py-0 pl-3 my-0 header-text text-white text-uppercase">
            {{ lang.tanulmanyi_tajekoztato_rendszer }}
          </p></v-col
        >
        <v-col :cols="12" :lg="6" :md="6" :sm="12">
          <v-btn
            @click="languageChange('hu')"
            class="mr-3 ml-1 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >hu</v-btn
          >
          <v-btn
            @click="languageChange('en')"
            class="mr-0 ml-3 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >en</v-btn
          >
          <v-btn
            @click="sugoDialog(3)"
            class="mr-3 ml-0 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >{{ lang.sugo }}</v-btn
          >
          <v-btn
            color="white"
            dark
            outlined
            class="mr-1 ml-3 float-right"
            href="https://ttr.sze.hu/Shibboleth.sso/Login?target=https://ttr.sze.hu/api/loginShib"
            v-if="show_login == true"
            elevation="0"
          >
            <v-icon left>mdi-login</v-icon>
            {{ lang.bejelentkezes }}
          </v-btn>
          <v-btn
            elevation="0"
            class="mx-3 float-right"
            @click="Logout"
            target="blank"
            color="white"
            dark
            outlined
            v-if="show_login == false"
          >
            <v-icon left>mdi-login</v-icon>
            {{ lang.kijelentkezes }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- class="mx-auto mb-5 mt-2 pa-5" -->
    <v-card elevation="0" width="80%" class="mx-auto bg-transparent mt-4">
      <v-row class="ma-0 pa-0">
        <v-col :cols="12" :lg="8" :md="8" :sm="12" class="ma-0 py-0 px-0">
          <v-card
            elevation="1"
            class="ma-0 pa-2 mb-4 custom-right-margin"
            v-if="show_login == false"
          >
            <v-row class="my-0 mx-2 py-1 px-0"
              ><p class="ma-0 pa-0 text-uppercase font-weight-medium text-h6">
                {{ lang.egyeni_elorehaladas }}
              </p></v-row
            >
            <v-row class="mx-2 my-0 pa-0 leiras pt-1 pb-5">{{
              lang.box_1_szoveg
            }}</v-row>
            <v-row class="ma-0 px-0 pb-2 pt-0">
              <v-col :cols="12" :lg="12" :md="12" :sm="12" class="ma-0 pa-0">
                <!-- v-if="show_user_plans" -->
                <v-autocomplete
                  :items="user_sample_plans"
                  item-value="Mintatanterv_kod"
                  item-text="Mintatanterv_nev"
                  v-model="user_selected_sample_plan"
                  :label="lang.mintaterv"
                  class="mx-2 mt-0 pt-1 pb-1"
                  :hide-details="true"
                  :flat="true"
                  item-color="uni_blue2"
                  color="uni_blue2"
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 pb-3">
              <v-col
                :cols="12"
                :lg="2"
                :md="2"
                :sm="2"
                class="ma-0 pa-0 d-flex justify-start align-end"
              >
                <!-- v-if="show_login == false" -->
                <!-- :disabled="user_selected_sample_plan == ''" -->
                <v-btn
                  elevation="0"
                  target="_blank"
                  @click="goToMatrix"
                  class="mx-3 float-right text-white"
                  color="uni_blue2"
                  >{{ lang.tovabb }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
          <v-card elevation="1" class="ma-0 pa-2 mb-4 custom-right-margin">
            <v-row class="mx-2 py-1 my-0 px-0 font-weight-medium text-h6">{{
              lang.online_tajekoztato
            }}</v-row>
            <v-row class="mx-2 pt-1 pb-5 my-0 px-0 leiras d-flex"
              ><p class="ma-0 pa-0">
                {{ lang.box_2_szoveg }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="sugoicon"
                      @click="sugoDialog(1)"
                      >mdi-help-circle-outline</v-icon
                    ></template
                  >
                  <p class="ma-0 pa-0">{{ lang.tovabbi_info }}</p>
                </v-tooltip>
              </p></v-row
            >
            <!-- <a
              text
              color="uni_blue m-2"
              class="link"
              target="_blank"
              href="https://neptun.sze.hu/on-line-kiadott-tajekoztatok"
              >{{ lang.elotte }}</a
            > -->
            <v-row class="mx-2 leiras d-flex">
              <v-alert text color="info" dense width="auto">
                <a
                  color="uni_blue m-2"
                  class="mx-1 pa-0 link_2 text-caption"
                  target="_blank"
                  href="https://neptun.sze.hu/on-line-kiadott-tajekoztatok"
                >
                  {{ lang.link }} <v-icon>mdi-arrow-right</v-icon>
                </a>
              </v-alert></v-row
            >
            <v-divider class="mx-4"></v-divider>
            <v-flex class="scroll">
              <v-treeview
                color="uni_blue"
                :items="items"
                open-on-click
                class="scroll"
                hoverable
              >
                <template v-slot:label="{ item }">
                  <v-btn
                    v-if="item.href"
                    plain
                    :to="`/tanulmanyi_tajekoztato/${
                      langName == 'hu' ? item.language == 'hu'? 'hu' : 'en' : 'en'
                    }/${item.href}`"
                    target="_blank"
                    color="uni_blue"
                    class="text-white"
                    ><span v-if="langName == 'hu'">{{ item.name }}</span
                    ><span v-else>{{ item.name_en }}</span></v-btn
                  >
                  <span v-else>{{
                    langName == "hu" ? item.name : item.name_en
                  }}</span>
                  <v-tooltip v-if="item.href" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="uni_blue"
                        plain
                        dark
                        small
                        class="mx-1"
                        v-bind="attrs"
                        v-on="on"
                        @click="downloadHomePdf(item)"
                      >
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span class="align-center"
                      >{{ lang.letoltes }}<br />{{ item.created }}</span
                    >
                  </v-tooltip>
                </template>
              </v-treeview>
            </v-flex>
          </v-card>
        </v-col>
        <v-col :cols="12" :lg="4" :md="4" :sm="12" class="ma-0 py-0 px-0">
          <v-card elevation="1" class="sticky pa-2 ma-0">
            <v-card class="" elevation="0">
              <v-row class="ma-0 px-2 py-1 font-weight-medium text-h6">{{
                lang.hallgatoi_tantervek
              }}</v-row>
              <v-row class="ma-0 px-2 py-0 pt-1 pb-5 leiras d-flex"
                ><p class="ma-0 pa-0">
                  {{ lang.box_3_szoveg }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="sugoicon"
                        @click="sugoDialog(2)"
                        >mdi-help-circle-outline</v-icon
                      ></template
                    >
                    <p class="ma-0 pa-0">{{ lang.tovabbi_info }}</p>
                  </v-tooltip>
                </p></v-row
              >
              <v-row class="py-0 px-2 ma-0">
                <v-container class="pa-0">
                  <v-radio-group v-model="radioGroup" @change="radioGroupGet">
                    <div class="d-flex mb-1">
                      <v-radio
                        :label="lang.tantargylista"
                        key="1"
                        class="ma-0 pa-0 mr-1"
                        value="1"
                        color="uni_blue2"
                      ></v-radio>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="sugoicon"
                            @click="sugoDialog(4)"
                            >mdi-help-circle-outline</v-icon
                          ></template
                        >
                        <p class="ma-0 pa-0">{{ lang.tovabbi_info }}</p>
                      </v-tooltip>
                    </div>
                    <div class="d-flex mb-1">
                      <v-radio
                        :label="lang.tantargyak_javasolt_felveteli_sorrendje"
                        key="2"
                        value="2"
                        class="ma-0 pa-0 mr-1"
                        color="uni_blue2"
                      ></v-radio>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="sugoicon"
                            @click="sugoDialog(5)"
                            >mdi-help-circle-outline</v-icon
                          ></template
                        >
                        <p class="ma-0 pa-0">{{ lang.tovabbi_info }}</p>
                      </v-tooltip>
                    </div>
                    <v-radio
                      :label="lang.tantargytematika_keresés"
                      key="3"
                      value="3"
                      color="uni_blue2"
                    ></v-radio>
                  </v-radio-group>
                </v-container>
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-autocomplete
                  :label="lang.nyelv"
                  :items="languages"
                  color="uni_blue2"
                  item-text="name"
                  item-value="id"
                  item-color="uni_blue"
                  return-object
                  v-model="search.lang"
                  v-if="radioGroup != 3"
                  @change="searchLanguageChange"
                  @keydown="deleterow(1)"
                >
                  <template v-slot:item="{ item }">
                    <p class="fontSize">{{ item.name }}</p>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-autocomplete
                  :label="lang.szak"
                  :items="faculties"
                  color="uni_blue2"
                  item-value="ID"
                  :item-text="`F${langName == 'en' ? '16' : '3'}`"
                  return-object
                  item-color="uni_blue"
                  v-model="search.faculty"
                  v-if="
                    (radioGroup != 3 && search.lang.name == 'Magyar') ||
                    (radioGroup != 3 && search.lang.name == '')
                  "
                  :disabled="search.lang.name == ''"
                  @input="searchFacultyChange"
                >
                  <template v-slot:item="{ item }">
                    <span class="fontSize" v-if="langName == 'hu'">
                      {{ item.F3 }} - {{ item.F5 }} - {{ item.F4 }} ({{
                        item.F2
                      }})
                    </span>

                    <span class="fontSize" v-if="langName == 'en'">
                      <span v-if="item.F16">{{ item.F16 }}</span>
                      <span v-else>{{ item.F3 }}</span> -
                      {{ item.SZINT_ANGOL }} - {{ item.TAGOZAT }} ({{
                        item.F2
                      }})
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="fontSize" v-if="langName == 'hu'">
                      {{ item.F3 }} - {{ item.F5 }} - {{ item.F4 }} ({{
                        item.F2
                      }})
                    </span>

                    <span class="fontSize" v-if="langName == 'en'">
                      <span v-if="item.F16">{{ item.F16 }}</span>
                      <span v-else>{{ item.F3 }}</span> -
                      {{ item.SZINT_ANGOL }} - {{ item.TAGOZAT }} ({{
                        item.F2
                      }})
                    </span>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  :label="lang.szak"
                  :items="faculties"
                  color="uni_blue2"
                  return-object
                  item-value="ID"
                  item-text="F16"
                  item-color="uni_blue"
                  v-model="search.faculty"
                  v-if="(radioGroup != 3 && search.lang.name == 'English')"
                  :disabled="search.lang.name == ''"
                  @change="searchFacultyChange"
                >
                  <template v-slot:item="{ item }">
                    <p class="fontSize">
                      <span v-if="item.F16 != null">{{ item.F16 }}</span
                      ><span v-else>{{ item.F3 }}</span> - {{ item.SZINT_ANGOL }} -
                      {{ item.TAGOZAT }} ({{ item.F2 }})
                    </p>
                  </template>
                  <template v-slot:selection="{ item }">
                    <p class="fontSize">
                      <span v-if="item.F16 != null">{{ item.F16 }}</span
                      ><span v-else>{{ item.F3 }}</span> - {{ item.SZINT_ANGOL }} -
                      {{ item.TAGOZAT }} ({{ item.F2 }})
                    </p>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-autocomplete
                  :label="lang.szakirany"
                  :items="specializations"
                  color="uni_blue2"
                  return-object
                  item-text="F7"
                  item-value="F6"
                  item-color="uni_blue"
                  v-model="search.specialization"
                  v-if="radioGroup != 3"
                  @change="searchSpecializationChange"
                  :disabled="search.faculty == null || search.lang.name == ''"
                >
                  <template v-slot:item="{ item }">
                    <p class="fontSize">{{ item.F7 }} ( {{ item.F6 }} )</p>
                  </template>
                  <template v-slot:selection="{ item }">
                    <p class="fontSize">{{ item.F7 }} ( {{ item.F6 }} )</p>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-autocomplete
                  :label="lang.mintaterv"
                  :items="samplePlans"
                  color="uni_blue2"
                  return-object
                  item-text="F10"
                  item-value="F9"
                  item-color="uni_blue"
                  v-model="search.sample_plan"
                  v-if="radioGroup != 3"
                  :disabled="
                    search.specialization == null ||
                    search.faculty == null ||
                    search.lang.name == ''
                  "
                >
                  <template v-slot:item="{ item }">
                    <p class="fontSize">{{ item.F10 }}</p>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-text-field
                  :label="lang.targy"
                  item-color="uni_blue"
                  color="uni_blue2"
                  v-model="subSearch.subject"
                  v-if="radioGroup == 3"
                />
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-text-field
                  :label="lang.targykod"
                  item-color="uni_blue"
                  color="uni_blue2"
                  v-model="subSearch.subjectCode"
                  v-if="radioGroup == 3"
                />
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-autocomplete
                  :label="lang.tanszek"
                  item-color="uni_blue"
                  color="uni_blue2"
                  :items="sub_departments"
                  return-object
                  item-value="F0"
                  item-text="F1"
                  v-model="subSearch.department"
                  v-if="radioGroup == 3"
                ></v-autocomplete>
              </v-row>
              <v-row class="py-0 px-0 ma-0">
                <v-autocomplete
                  :label="lang.tanszekkod"
                  item-color="uni_blue"
                  color="uni_blue2"
                  :items="sub_departments"
                  return-object
                  item-value="F0"
                  item-text="F0"
                  v-model="subSearch.department"
                  v-if="radioGroup == 3"
                ></v-autocomplete>
              </v-row>
              <v-row class="py-2 px-2 ma-0">
                <v-btn
                  color="uni_blue"
                  class="text-white"
                  v-if="radioGroup == 1"
                  target="_blank"
                  @click="goToTantargyLista"
                  :disabled="
                    search.sample_plan == null ||
                    search.faculty == null ||
                    search.specialization == null ||
                    search.lang.name == ''
                  "
                  >{{ lang.kereses }}</v-btn
                >
                <!-- :to="`/tantargy_lista/${langName}/${search.sample_plan.F9}`" -->

                <v-btn
                  color="uni_blue"
                  class="text-white"
                  v-if="radioGroup == 2"
                  target="_blank"
                  :to="`/matrix/${
                    langName == 'en' || search.lang.name == 'English'
                      ? 'en'
                      : 'hu'
                  }/${search.sample_plan.F9}`"
                  :disabled="
                    search.sample_plan == null ||
                    search.faculty == null ||
                    search.specialization == null ||
                    search.lang.name == ''
                  "
                  >{{ lang.kereses }}</v-btn
                >
                <!-- @click="goToMatrixSima" -->
                <!-- :to="`/matrix/${langName}/${search.sample_plan.F9}`" -->
                <v-btn
                  color="uni_blue"
                  class="text-white"
                  v-if="radioGroup == 3"
                  target="_blank"
                  @click="getSearchedSubjects"
                  >{{ lang.kereses }}</v-btn
                >
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <div class="text-center">
      <v-dialog v-model="sdialog" width="70%">
        <v-card>
          <v-card-text class="m-0 p-0">
            <Sugo :type="sugoType" :title="lang.sugo" :langName="langName" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="subjectDialog" width="70%" scrollable>
        <v-card class="p-3">
          <div class="d-flex justify-end">
            <v-icon @click="subjectDialog = false">mdi-close</v-icon>
          </div>
          <v-card-text class="m-0 p-0">
            <TantargyTematika
              :subject="searched_Subjects"
              :lang="lang"
              :search="subSearch"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-alert
      class="alert"
      dense
      outlined
      absolute
      type="warning"
      max-width="30%"
      v-model="alertShow"
      transition="scale-transition"
      >{{ alertText }}</v-alert
    >
    <v-overlay :value="loadingCircular" opacity="0.9">
      <v-progress-circular
        indeterminate
        size="250"
        width="10"
        color="uni_blue2"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import TantargyTematika from "@/components/TantargyTematika.vue";
import Header from "@/components/Header.vue";
import Sugo from "@/components/Sugo.vue";
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  name: "Home",
  components: {
    Sugo,
    Header,
    TantargyTematika,
  },
  data: () => ({
    loadingCircular: false,
    sdialog: false,
    subjectDialog: false,
    lang: hu,
    langName: "hu",
    treeSearch: {
      year: "",
      samplePlan: "",
    },
    search: {
      lang: {
        id: -1,
        name: "",
      },
      department: {},
      faculty: {
        ID: 0,
        F3: "",
      },
      specialization: {
        F7: "",
      },
      sample_plan: {
        F9: "",
        F10: "",
      },
    },
    subSearch: {
      subject: "",
      subjectCode: "",
      department: "",
      departmentCode: "",
    },
    subject: {},
    subjects: [],
    active: [],
    open: [],
    selected: true,
    selectedTantargy: null,
    tantargylista: true,
    tantargymatrix: false,
    radioGroup: "1",
    languages: [
      { id: 0, name: "Magyar" },
      { id: 1, name: "English" },
    ],
    department: [],
    specializations: [],
    samplePlans: [],
    items: [],
    semesterautocomplete: [],
    samplePlanautocomplete: [],
    faculties: [],
    sub_departments: [],
    searched_Subjects: [],
    user_sample_plans: [],
    show_user_plans: false,
    show_login: false,
    alertType: "success",
    alertText: "",
    alertShow: false,
    user_selected_sample_plan: "",
    sugoType: 3,
  }),
  async mounted() {
    try {
      if (Object.keys(this.$route.params).length > 0) {
        this.subSearch.subjectCode = this.$route.params.kod;
        if (this.$route.params.kod == "N_ED20_A") {
          this.subSearch.subjectCode == "N_ED20/A";
        }
        this.getSearchedSubjects();
      }
      const responseCheckLogin = await this.$http.get("/checkLogin");
      if (responseCheckLogin.logged == true) {
        this.show_login = false;
        this.user_sample_plans = responseCheckLogin.user_sample_plans;
      } else {
        this.show_login = true;
      }
      this.alertText = responseCheckLogin.valasz;
      if (responseCheckLogin.valasz != "") {
        this.alertShow = true;
        var myTimer = window.setTimeout(() => {
          this.alertShow = false;
        }, 3000);
        if (responseCheckLogin.user_sample_plans) {
          this.show_user_plans = true;
        }
      }

      this.loadingCircular = true;
      const response = await this.$http.post("/gettree");
      this.items = response.tree;
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingCircular = false;
    }
  },
  methods: {
    deleterow(id) {
      if (id == 1) {
        this.search.lang = {
          id: "",
          name: "",
        };
      }
    },
    async radioGroupGet() {
      try {
        if (this.radioGroup == "3") {
          this.loadingCircular = true;
          if (this.sub_departments.length == 0) {
            const response_ = await this.$http.post("/subdepartments");
            this.sub_departments = response_.departments;
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingCircular = false;
      }
    },
    downloadHomePdf(item) {
      try {
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        if (this.langName == "hu") {
          link.href = "https://ttr.sze.hu/api/tmp/" + item.link.pdf_hu + ".pdf";
          // link.href =
          //   "http://10.1.3.103/tanulmanyitajekoztato/api/tmp/" +
          //   item.link.pdf_hu +
          //   ".pdf";
          link.setAttribute("download", item.link.pdf_hu + ".pdf");
        } else {
          link.href = "https://ttr.sze.hu/api/tmp/" + item.link.pdf_en + ".pdf";
          // link.href =
          //   "http://10.1.3.103/tanulmanyitajekoztato/api/tmp/" +
          //   item.link.pdf_en +
          //   ".pdf";
          link.setAttribute("download", item.link.pdf_en + ".pdf");
        }

        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);
      }
    },
    goToTantargyLista() {
      let talalat = false;
      this.samplePlans.forEach((element) => {
        if (this.search.sample_plan.F10 == element.F10) {
          talalat = true;
        }
      });
      if (talalat) {
        this.$store.commit("setTantargyLista", this.search.sample_plan.F9);
        const lang =
          this.langName == "en" || this.search.lang.name == "English"
            ? "en"
            : "hu";
        let route = this.$router.resolve({
          path: "tantargy_lista/" + lang + "/" + this.search.sample_plan.F9,
        });
        window.open(route.href);
      } else {
        this.alertText = this.lang.nincs_targy;
        this.alertShow = true;
        var myTimer = window.setTimeout(() => {
          this.alertShow = false;
        }, 3000);
      }
    },
    goToMatrixSima() {
      let talalat = false;
      this.samplePlans.forEach((element) => {
        if (this.search.sample_plan.F10 == element.F10) {
          talalat = true;
        }
      });
      if (talalat) {
        this.$store.commit("setMatrix", this.search.sample_plan.F9);
        let route = this.$router.resolve({ path: "/matrix/" + this.langName });
        window.open(route.href);
      } else {
        this.alertText = this.lang.nincs_targy;
        this.alertShow = true;
        var myTimer = window.setTimeout(() => {
          this.alertShow = false;
        }, 3000);
      }
    },
    async getSearchedSubjects() {
      this.searched_Subjects = [];
      this.subSearch.subject = this.subSearch.subject.trim();
      this.subSearch.subjectCode = this.subSearch.subjectCode.trim();

      if (Object.values(this.subSearch).every((x) => x === null || x === "")) {
        this.alertText = this.lang.nincs_targy;
        this.alertShow = true;
        var myTimer = window.setTimeout(() => {
          this.alertShow = false;
        }, 3000);
        return;

      }
      try {
        const response = await this.$http.post(
          "/getSearchedSubjects",
          this.subSearch
        );
        if (response.subjects.length == 0) {
          this.alertText = this.lang.nincs_targy;
          this.alertShow = true;
          myTimer = window.setTimeout(() => {
            this.alertShow = false;
          }, 3000);
          return;
        }
        this.searched_Subjects = response.subjects;
        this.subjectDialog = true;
      } catch (e) {
        console.error(e);
      }
    },
    sugoDialog(id) {
      this.sugoType = id;
      this.sdialog = true;
    },
    async Login() {
      try {
        var post = {
          // neptunCode: 'X55P82' /*Marci*/
          // neptunCode: 'BFOM1R' /*Marek*/
          // neptunCode: 'E7PWEK' /*Ricsó*/
          // neptunCode: 'BNMI94' /*Pőcze*/
          neptunCode: "AQAWC1",
          // neptunCode: "IH7KLQ",
        };
        const response = await this.$http.post("studentLogin/", post, {
          timeout: 0,
        }); //Ez a lekérdezés féléveinek
        if (response.login === true) {
          this.show_login = false;
          this.show_user_plans = true;
          this.$store.commit("setStudent", response.student);
          this.user_sample_plans = response.sample_plans;
        } else {
          this.alertType = "uni_blue2";
          this.alertText = this.lang.nincs_aktiv_felev;
          this.alertShow = true;
          var myTimer = window.setTimeout(() => {
            this.alertShow = false;
          }, 3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
    fastSearch() {
      let talalat = false;
      this.samplePlanautocomplete.forEach((element) => {
        if (element.mintaterv_kod == this.treeSearch.samplePlan.mintaterv_kod) {
          talalat = true;
        }
      });
      if (talalat) {
        let route = this.$router.resolve({
          path:
            "/tanulmanyi_tajekoztato/" +
            this.langName +
            "/" +
            this.treeSearch.samplePlan.mintaterv_kod,
        });
        window.open(route.href);
      } else {
        this.alertText = this.lang.nincs_targy;
        this.alertShow = true;
        var myTimer = window.setTimeout(() => {
          this.alertShow = false;
        }, 3000);
      }
    },
    async goToMatrix() {
      let route = this.$router.resolve({
        path:
          "/tanulo_matrix/" +
          this.langName +
          "/" +
          this.user_selected_sample_plan.Modulkod +
          "/" +
          this.user_selected_sample_plan.Mintatanterv_kod,
      });
      window.open(route.href);
      // this.$store.commit("setSamplePlan", this.user_selected_sample_plan);
      // // this.$router.push('/user_matrix')
      // let route = this.$router.resolve({
      //   path: "/tanulo_matrix/" + this.langName,
      // });
      // window.open(route.href);
    },
    Logout() {
      this.show_login = true;
      this.show_user_plans = false;
      this.$cookies.remove("studentToken");
      this.$store.commit("setSamplePlan", null);
      this.$store.commit("setStudent", null);
      window.open("https://ttr.sze.hu/Shibboleth.sso/Logout");
    },
    languageChange(type) {
      if (type == "en") {
        this.lang = en;
        this.langName = "en";
      } else {
        this.lang = hu;
        this.langName = "hu";
      }
      this.$cookies.set("lang", type);
    },
    async semesterSearchChange() {
      try {
        const response = await this.$http.post(
          "semesterSearchChange/",
          this.treeSearch.year
        );
        this.samplePlanautocomplete = response.sample_plan;
      } catch (e) {
        console.error(e);
      }
    },

    async searchLanguageChange() {
      try {
        this.faculties = [];
        this.search.faculty = {
          ID: -1,
          F3: "",
        };
        this.search.specialization = "";
        this.search.sample_plan = "";
        const response = await this.$http.post(
          "getHomeFaculties/",
          this.search.lang
        );
        this.faculties = response.faculties;
      } catch (e) {
        console.error(e);
      }
    },

    async searchFacultyChange() {
      try {
        if (this.search.faculty != "" && this.search.faculty != null) {
          this.search.specialization = "";
          this.search.sample_plan = "";
          const response = await this.$http.post(
            "getHomeSpecializations/",
            this.search
          );
          this.specializations = response.specializations;
          this.search.specialization =
            response.specializations[response.specializations.length - 1];
          this.searchSpecializationChange();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async searchSpecializationChange() {
      try {
        if (
          this.search.specialization != "" &&
          this.search.specialization != null
        ) {
          this.search.sample_plan = "";
          const response = await this.$http.post(
            "getHomeSamplePlans/",
            this.search
          );
          this.samplePlans = response.sample_plans;
          this.search.sample_plan =
            this.samplePlans[this.samplePlans.length - 1];
        }
      } catch (e) {
        console.error(e);
      }
    },

    regi() {
      window.open(
        "https://neptun.sze.hu/on-line-kiadott-tajekoztatok",
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
.sticky {
  position: sticky;
  z-index: 5;
  top: 5vh;
}

.scroll {
  overflow-x: auto;
  width: max-content;
  min-width: 100%;
  z-index: 5;
}

.fontSize {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}

.v-icon::hover {
  color: transparent;
}

.v-icon::after {
  color: transparent;
}
.header-row {
  background: linear-gradient(135deg, #00adcb 20%, #0c3c60);
}
.header-text {
  font-size: 1.5rem;
  font-weight: 600;
}
.alert {
  color: white;
  position: fixed;
  left: 50%;
  bottom: 2rem;
  transform: translate(-50%, 50%);
  margin: 0 auto; /* Without this the box extends the width of the page*/
}
.link {
  margin-left: 0.6rem;
  display: inline-block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}
.link::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 0.1em;
  transform: scaleX(0);
  background-color: #0c3c60;
  transform-origin: bottom right;
}
.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-in;
}
.leiras {
  font-size: 0.8rem;
}
.sugoicon {
  cursor: pointer;
}
.link_2 {
  text-decoration: none;
}
.custom-right-margin {
  margin-right: 16px !important;
}

@media (max-width: 960px) {
  /* Vuetify MD breakpoint is 960px */
  .custom-right-margin {
    margin-right: 0 !important;
  }
}
</style>
