<template>
  <div class="py-1">
    <p class="pa-0 ma-0 text-center display-5">{{ title }}</p>
    <v-divider class="mx-4" v-if="type == 1 || type == 3"></v-divider>
    <v-card
      elevation="0"
      class="my-1 mx-2"
      v-if="(type == 1 || type == 3) && langName == 'hu'"
    >
      <v-card-title>
        <span> Tanulmányi tájékoztató menüpont: (Bal oldalon) </span>
      </v-card-title>
      <v-card-text>
        <p>
          A 2010/2011 tanév tavaszi félévétől kezdve Egyetemünk ezen felület
          segítségével teszi közzé a tanulmányi tájékoztató kiadványokat.<br />
          A kiadványok tartalmazzák az adott tanévben / félévben induló szakok,
          szakirányok listáját.<br />
        </p>
        <p>
          Nyomtatható pdf verzióban mutatja meg a felületen jelzett tanév /
          félévben, azaz a kiadás pillanatában érvényes,
        </p>
        <ul type="-">
          <li>
            a szakra vonatkozó szöveges részt (képzési cél, szakmai gyakorlat,
            záróvizsga követelmények, stb.)
          </li>
          <li>
            tantárgyi táblázatot (tantárgy kódja, neve, óraszáma, beszámolási
            formája, kreditpontja, előtanulmányi követelménye)
          </li>
          <li>
            javasolt tantárgyfelvételi rendet (mátrix) – az egyes félévekben
            mely kötelező tantárgyak felvételét javasoljuk
          </li>
        </ul>
        <p>
          Az aktuális mintatanterveket a jobb oldali lenyíló, 'Hallgatói
          tantervek' kiválasztó menüben célszerű leszűrni, mert az követi a
          szakok mintatantervét érintő változtatásokat (pl. Szenátus által
          elfogadott határozatok), a .pdf változat viszont csak a félév
          kezdetekor, a kiadás pillanatában érvényes.
        </p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      class="my-1 mx-2"
      v-if="(type == 1 || type == 3) && langName == 'en'"
    >
      <v-card-title>
        <span> Study brochure (On the left side) </span>
      </v-card-title>
      <v-card-text>
        <p>
          Since the spring semester of the academic year 2010/2011, the
          University has been publishing its study brochures using this
          platform.<br />
        </p>
        <p>
          The brochures contain the list of majors and specialisations starting
          in the given academic year/semester. <br />The following pieces of
          information in the academic year/semester indicated on the interface,
          i.e. valid at the time of issue are shown in a printable pdf version:
        </p>

        <ul type="-">
          <li>
            a textual part related to the major (aim of the education,
            internship, requirements of the final examination, etc.)
          </li>
          <li>
            a table of subjects (subject code, subject name, number of lessons,
            form of reporting, credit points, pre-study requirement)
          </li>
          <li>
            a proposed plan of subject registration (matrix) - which compulsory
            subjects are proposed for each semester
          </li>
        </ul>
        <p>
          The current model curricula can be downloaded from the &quot;Student
          curricula&quot; drop-down menu on the right, because it keeps track of
          changes to the model curricula of the majors (e.g. decisions adopted
          by the Senate), while the .pdf version is only valid at the beginning
          of the semester, at the moment of publication.
        </p>
      </v-card-text>
    </v-card>
    <v-divider class="mx-4" v-if="type == 2 || type == 3"></v-divider>
    <v-card
      elevation="0"
      class="my-1 mx-2"
      v-if="(type == 2 || type == 3 || type == 4) && langName == 'hu'"
    >
      <v-card-title>
        <p class="m-0 p-0">
          <span>
            Hallgatói tantervek - Tantárgylista rádió gomb (Jobb oldalon)
          </span>
        </p>
      </v-card-title>
      <v-card-text>
        <p class="text-center">
          <b>Tantárgylista rádió gomb (rádió gomb):</b>
        </p>
        <p>
          A felület a hallgató személyes paramétereinek beállítása után az adott
          mintatantervhez tartozó tantárgyak listáját mutatja meg, a tantárgyak
          alapadataival (tantárgy kódja, neve, óraszáma, beszámolási formája,
          kreditpontja, előtanulmányi követelménye).
        </p>
        <p class="text-danger">
          Az oldal naprakészen tartalmazza továbbá az adott mintatantervet
          érintő határozatokat (Szenátus) is, melyek a szak mintatantervének
          változtatását eredményezték.
        </p>

        <p><i>Személyes paraméterek:</i></p>
        <ul type="-">
          <li>a szak nyelve,</li>
          <li>a szak neve,</li>
          <li>a szakirány neve</li>
        </ul>
        <p>
          ha még nem választott szakirányt, akkor ezt a feltételt nem kell
          kitölteni,
        </p>
        <p>
          ha a szak, és nem a szakirány tantervi tábláját szeretné megnézni,
          akkor sem kell kitölteni ezt a feltételt,
        </p>
        <p>
          - tanterv neve – a Neptun rendszerben a Tanulmányok/Mintatanterv
          menüponton belül a „…” (kék) gombra kattintva feljövő mintatanterv(ek)
          neve.
        </p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      class="my-1 mx-2"
      v-if="(type == 2 || type == 3 || type == 4) && langName == 'en'"
    >
      <v-card-title>
        <p class="m-0 p-0">
          <span> Subject list radio button (radio button): </span>
        </p>
      </v-card-title>
      <v-card-text>
        <p>
          After setting the student's personal parameters, the interface
          displays the list of subjects for the given sample curriculum, with
          the basic data of the subjects (subject code, name, number of lessons,
          form of reporting, credit points, pre-study requirement).
        </p>
        <p class="text-danger">
          The site also contains up-to-date information on the decisions
          concerning the model curriculum (Senate) that have led to changes in
          the curriculum of the major.
        </p>

        <p><i>Personal parametres:</i></p>
        <ul type="-">
          <li>language of the major,</li>
          <li>name of the major</li>
          <li>name of the specialisation</li>
        </ul>
        <p>
          if the student has not chosen a specialisation yet, this condition
          does not need to be completed,
        </p>
        <p>
          if you would like to look at the curriculum table for the major and
          not the specialisation, you do not need to fill in this condition
          either,
        </p>
        <p>
          - name of the curriculum - the name of the sample curriculum/curricula
          coming up by clicking on the &quot;...&quot; (blue) button in the
          Studies/Model Curriculum menu in Neptun.
        </p>
      </v-card-text>
    </v-card>
    <v-divider class="mx-4" v-if="type == 2 || type == 3"></v-divider>
    <v-card
      elevation="0"
      class="my-1 mx-2"
      v-if="(type == 2 || type == 3 || type == 5) && langName == 'hu'"
    >
      <v-card-title>
        <span>
          Hallgatói tantervek - Tantárgyak javasolt felvételi rendje - mátrix
          (Jobb oldalon)
        </span>
      </v-card-title>
      <v-card-text>
        <p>
          A felület a hallgató személyes paramétereinek beállítása után az adott
          mintatantervhez tartozó
          <b>tantárgyfelvételt kívánja segíteni.</b> Ez egy interaktív táblázat,
          mely beépített számítási és kijelzési funkciókkal rendelkezik.
        </p>
        <p>
          A Hallgató az oldalra való bejelentkezés után (ami a címtáras belépési
          azonosítóval és jelszóval történik meg) a fejlécen a „Bejelentkezés”
          gombtól balra található lenyíló mezőből tudja kiválasztani a
          megtekinteni kívánt aktív mintatantervét. A mintatanterv kiválasztását
          követően a „Mátrix” gomb megnyomásával jut a saját felületére.
        </p>
        <p>
          <b
            ><i>Használata segítségével</i> megtervezhető a felületen - a
            tantárgyak mozgatásával és az előtanulmányok megjelenítésével - a
            tanulmányok legoptimálisabb folytatása.</b
          >
        </p>
        <p><u>Ehhez információt kaphat:</u></p>
        <ul>
          <li>
            <b>Az előrehaladás jelenlegi állapotáról:</b> a már teljesített
            tantárgyak bejelölésével a félévenkénti, illetve az összesen
            teljesített kreditpontszámról
          </li>
          <li>
            <b
              >A már teljesített tantárgyakra történő ráépülésről és
              előtanulmányi követelményekről:</b
            >
            a már teljesített tantárgyak bejelölésével, mely tantárgyak
            felvétele lehetséges a továbbiakban, ezáltal előremutatást kaphat
            <b>a tanulmányok várható további időtartamáról</b>
          </li>
          <li>
            A mintatantervben szereplő egyes
            <b
              >tantárgycsoportokból összesen teljesítendő
              kreditpontszámról/tantárgy darabszámról,</b
            >
            illetve - a teljesített tantárgyak bejelölésével – arról, hogy a
            választható csoportból teljesített tantárgyak
            kreditpontja/darabszáma elegendő-e az adott csoport teljesítéséhez
          </li>
        </ul>
        <p>
          A Mátrix táblázatos része felett található egy csúszka „Nézet 1”
          alapértelmezett állásban. Ez a mintatantervet a jogszabályi képzési
          idővel jeleníti meg, a „Nézet 2” pedig a hallgató által ténylegesen
          elhasznált félévek (esetleges túlcsúszás) szerint mutatja meg az
          előrehaladást.
        </p>
        <p>
          Amennyiben a jelzett felületen a tanulmányai megtekintésével
          kapcsolatban valamilyen problémát észlel, kérjük azt a
          <a href="mailto:ttr@sze.hu.">ttr@sze.hu.</a>
          címen jelezze.
        </p>
      </v-card-text>
    </v-card>
    <v-card
      elevation="0"
      class="my-1 mx-2"
      v-if="(type == 2 || type == 3 || type == 5) && langName == 'en'"
    >
      <v-card-title>
        <span>
          Student curricula - a proposed plan of subject registration (matrix)
          (on the right side)
        </span>
      </v-card-title>
      <v-card-text>
        <p>
          After setting the student&#39;s personal parameters, the interface is
          <b
            >designed to help the student to select the subjects for the given
            sample curriculum.</b
          >
          It is an interactive spreadsheet with built-in calculation and display
          functions.
        </p>
        <p>
          After logging in to the site (which is done with the directory login
          and password), the student can select the active sample syllabus they
          wish to view from the drop-down box to the left of the
          &quot;Login&quot; button in the header. Once you have selected a
          sample curriculum, you can access its interface by clicking on the
          &quot;Matrix&quot; button.
        </p>
        <p>
          <b>
            <i>You can use it</i> to plan the most optimal continuation of your
            studies by moving subjects around the interface and displaying
            pre-studies.
          </b>
        </p>
        <p><u>To be able to do this, students can get information about:</u></p>
        <ul>
          <li>
            <b>Current progress:</b> by ticking the subjects already completed,
            the number of credits completed per semester and the total number of
            credits completed
          </li>
          <li>
            <b
              >About building on subjects already completed and pre-study
              requirements:</b
            >
            by ticking the subjects already completed, students can find out
            which subjects can be registered in the future and thus get an
            indication of the expected duration of their studies
          </li>
          <li>
            <b
              >The total number of credits/courses to be completed from each
              group of subjects in the model curriculum</b
            >
            and, by ticking the subjects completed, whether the number of
            credits/courses completed from the optional group is sufficient to
            complete the given group
          </li>
        </ul>
        <p>
          Above the tabular part of the Matrix there is a slider &quot;View
          1&quot; in default position. It shows the sample curriculum with the
          statutory training time, while &quot;View 2&quot; shows the progress
          according to the semesters actually completed by the student (possible
          over-slip in their studies).
        </p>
        <p>
          In case you have any problems viewing your studies on this interface,
          please report them to <a href="mailto:ttr@sze.hu.">ttr@sze.hu.</a>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  props: {
    type: Number,
    title: String,
    langName: String,
  },
  data: () => ({}),
};
</script>

<style>
</style>