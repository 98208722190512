import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiURL: "http://10.1.3.103/tanulmanyitajekoztato/api/",
    apiURL: "https://ttr.sze.hu/api/",
    lang: hu,
    navigation_drawer: true,
    user: null,
    student: null,
    studentSamplePlan: null,
    tantargy_lista: null,
    matrix: null
  },
  mutations: {
    setLanguage(state, language) {
      language == "hu" ? state.lang = hu: state.lang = en;
    },
    setUser(state, user) {
      state.user = user;
    },
    setStudent(state, student) {
      state.student = student
    },
    setSamplePlan(state, samplePlan) {
      state.studentSamplePlan = samplePlan
    },
    setTantargyLista(state, samplePlan) {
      state.tantargy_lista = samplePlan
    },
    setMatrix(state, samplePlan) {
      state.matrix = samplePlan
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      paths: ["student", "studentSamplePlan", "tantargy_lista", "matrix"]
    }),
    // vuexPersistedstate({
    //   paths: ["student"]
    // })
  ]
});
